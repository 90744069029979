.download-section svg {
  font-size: 3rem;
  color: var(--second-main-color);
  padding: 1rem;
  border-radius: 1rem;
  width: 100%;
  height: 200px;
  border: 1px solid #8584844e;
}
.download-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-file input {
  height: 100%;
  padding-top: 2.8rem;
  padding-left: 2.2rem;
}
