.nav-link,
.nav-item {
  font-size: 1.1rem !important;
  text-transform: capitalize;
  transition: 0.5s;
  color: #000;
}
.nav-item .nav-link {
  margin-right: 0px;
  color: #000;
}
:focus-visible {
  outline: none !important  ;
}
.nav-link:last-child,
.nav-link:nth-child(3) {
  margin-right: 0px;
}
@media (min-width:993px) {
.nav-item.dropdown:hover .dropdown-menu {
  display: block !important;
} 
}

.logo {
  width: 100px;
  height: auto;
}
.navbar-nav .nav-link.active,
.nav-link:focus,
.nav-link:hover {
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

a {
  text-decoration: none !important;
  color: #000;
}
.nav-container {
  width: 100%;

}
.nav-container h1{
  color: var(--main-color);
}
.nav_links {
  width: 100%;
}
.navbar-toggler-icon{
  background: none;
}
.navbar-toggler{
  background: var(--main-color);
  border: none;
  color: var(--white-color);
  padding: .5rem .7rem;
}
.offcanvas-body{
  padding-top: 0;
}
.offcanvas-body .nav-link {
  padding: 2px 10px !important;
  margin-right: 10px;
  border-radius: .25rem;
}
.offcanvas-body .nav-link span{
  width: 30px;
}
.nav-sm-icon a {
  font-size: 1.2rem;
  margin-right: 1rem;
}
.nav-container nav {
  /* background-color: #0000004a !important; */
}
/* .sticky-top {
  position: fixed !important;
  background-color: var(--white-color) !important;
  z-index: 5;
} */
.sticky-top nav {
  background: var(--white-color) !important;
}
.sticky-top .nav-link,
.nav-item {
  color: var(--black-color);
}
/* .nav-container nav {
  position: absolute !important;
  width: 100% !important;
  top: 0;
    left: 0;
  transition: 0.5s;
} */
.shadow-sm nav {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.nav-top-links svg{
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: .25rem;
  color: var(--white-color);
  background-color: var(--main-color);
  margin-right: 2px;
}
.nav-top-links a{
  text-transform: none;
  padding: 0 !important;
}
.nav-container nav {
  background-color: #FFFFFF;
}
@media (max-width: 992px) {
  /* .nav-container nav, .nav-container {
    position: relative !important;
    top: 0;
    border-bottom: 1px solid #dee2e6;
    background-color: var(--white-color) !important;
  } */
  .nav-sm-icon a {
    margin-right: 5px;
    font-size: 1.5rem;
  }
  .offcanvas-body .nav-link {
    padding-left: 5px !important;
  }
  .nav-link, .nav-item{
    color: var(--black-color);
    margin-bottom: 5px;
  }
  .navbar-toggler {
    position: absolute;
    left: 10px;
  }
  .navbar{
    padding: 0;
  }
  .dropdown-menu{
    border: none;
  }
  .nav-link-btn{
    position: absolute;
    bottom: 0;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .sticky-top {
    position: fixed !important;
    transition: .5s;
  }
}

.desktop-toggle-btn{
    background-color: var(--main-color) !important;
}
.btn-close{
  margin-left: auto;
}