
.footer-section ul {
  margin: 0;
  padding: 0;
}

.footer-section ul li a:hover{
    color: var(--main-color);
}
.contact-links li a {
  font-size: 2rem;
}
.newsletter-box {
  background-color: var(--main-color);
  /* position: relative; */
  /* padding: 1rem; */
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;
  box-shadow: 0px 10px 10px #e5e5e5;
}
.newsletter-box svg {
  height: 100px;
  width: 100px;
  padding: 10px;
  font-size: 3rem;
  color: var(--white-color);
  background-color: var(--second-main-color);
}
.newsletter-box h4, .newsletter-box h2{
    color: var(--white-color);
    margin-bottom: 0;
}

.news-input{
    display: flex;
    align-items: baseline;
    color: var(--white-color);
}
.news-input label{
    font-size: 1.5rem;
    margin: 0 1rem;
    align-self: baseline;
}
.news-input input{
    border: none;
    background-color: transparent;
    color: var(--white-color);
    width: 275px;
    border-bottom: 1px solid var(--white-color);
}
.copyright-section a{
    transition: .2s;
}
.copyright-section a:hover{
    color: var(--second-main-color);
}