tbody,
td,
tfoot,
th,
thead,
tr {
    border: 1px solid gray !important;
    padding: 10px !important;
    margin: 0;
}

table {
    border-collapse: separate !important;
    box-sizing: border-box !important;
    text-indent: initial !important;
    border-spacing: 2px !important;
    border: 1px solid gray !important;
    padding: 1rem;
    border-radius: .25rem;
    border-spacing: 0px !important;
    margin: 0 !important;
}