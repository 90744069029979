.service-section {
  margin-top: 3rem;
  /* height: auto; */
}

.s-content {
  background: var(--white-color);
  padding: 1rem;
}

.service-card {
  background: var(--white-color);
  padding: 1rem;
  border: 1px solid #e5e5e5;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.s-card-img img {
  height: 250px;
}

/* .s-card-img, */
.s-card-img img {
  width: 100%;
  /* height: 250px; */
  /* border-radius: 1rem 1rem 0 0; */
}

.s-content h4 {
  height: 55px;
  /* overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; */
}

.s-content h4 a:hover {
  color: var(--black-color);
}

.custom-file-upload {
  cursor: pointer;
}

.inner-image .custom-file-upload {
  height: 300px;
}

.inner-image .custom-file-upload img {
  object-fit: contain;
}

.service-card-inner img {
  height: 500px;
  border-radius: 1rem 1rem 0 0 !important;
}

.photo-gallery img {
  border-radius: 0 !important;
  height: auto;
}

.academy-title-box {
  height: 60px !important;
  overflow-y: auto !important;
  margin-bottom: 20px !important;
}

.inner-academy-image-size {
  opacity: 0;
}

.inneracademytitlesize {
  height: 100px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.inneracademypehrasize {
  height: 120px;
  overflow-y: auto;
}