body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.dropbox {
  text-align: center;
  padding: 20px;
  width: 90%;
  margin: auto;
  margin-top: 8px;
}

.dropbox-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.dropbox-container img {
  height: 200px;
  width: 200px;
  margin-right: 15px;
}

.dropbox-container .btn {
  padding: 15px;
  background-color: #de1a1a;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.dropbox-container.btn:hover {
  background-color: #945c5c;
}

.drop-image {
  width: 190px;
}

.image-card {
  width: 200px;
  height: 200px !important;
}

.modal-backdrop {
  z-index: 1055 !important;
}

.dropbox-bg {
  background-color: cornsilk;
  border-radius: 7px;
}

.gallery-custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 25px;
  width: 200px;
  height: 200px;
  border: 2px solid #777f80;
  margin-left: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5%;
  padding: 0 !important;
}

.img-upload:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777f80;
  width: 200px;
  height: 200px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

.img-wrap {
  position: relative;
  width: 190px;
  height: 190px;
  overflow: hidden;
}

.gallery-custom-file-upload input[type="file"] {
  display: none;
}

.gallery-custom-file-upload img {
  width: 190px !important;
  height: 190px !important;
  border-radius: 3% !important;
}

.image-main {
  position: relative;
}

.image-main span svg {
  position: absolute;
  right: 48px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: var(--black-color);
  color: var(--white-color);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}

.image-main span {
  /* background-color: var(--white-color); */
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.overlay-custom-class-name {
  z-index: 9999 !important;
}

#photo-upload[type="file"] {
  display: none;
  cursor: pointer;
}

#cover-photo-upload[type="file"] {
  display: none;
  cursor: pointer;
}

#inner-photo-upload[type="file"] {
  display: none;
  cursor: pointer;
}

.image-main-2 span svg {
  right: 80px;
}

.Active, .Yes {
  color: #25D366 !important;
}

.Inactive, .No {
  color: #FF0000 !important;
}

.sequentialize-btn {
  width: 32px !important;
  height: 30px !important;
}

.ck-blurred,
.ck-focused,
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  height: 200px;
}


/* ****** Donar ****** */
.donor-description {
  overflow: auto;
  height: 380px;
  padding-bottom: 50px;
}

.donor-card {
  position: relative;
  background: var(--white-color);
  border-radius: 1rem;
  overflow: hidden;
  max-height: 450px;
  height: 100%;
}
.toggle-donar .form-switch .form-check-input {
  width: 2.9em;
}

.toggle-donar .form-check-input {
  padding: 0.6rem !important;
}