.story-card{
    position: relative;
    background: var(--white-color);
    border-radius: 1rem;
    overflow: hidden;
    max-height: 550px;
}
.storie-img{
    width: 200px;
    height: 200px;
    margin: 0 auto;
    text-align: center;margin-top: 1.5rem;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.story-content {
    padding: 1rem;
    height: auto;
}

.stories-description{
overflow: auto;
max-height: 96px;
}
