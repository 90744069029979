@media screen and (max-width: 768px) {
    .carousel-caption h2 {
        font-size: 2.5em;
    }

    .carousel-caption h3 {
        font-size: 1.5rem;
    }

    .default-btn,
    .optional-btn {
        font-size: 12px;
        padding: 10px !important;
    }

}

.default-btn,
.optional-btn {
    padding: 10px 20px;
    color: var(--white-color);
    margin-right: 1rem;
    border-radius: 2px;
    transition: 0.5s;
}

.optional-btn {
    background-color: var(--white-color);
    color: var(--main-color);
}

.optional-btn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.default-btn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

.Main-slider {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url("../../assets/images/slider-images/1.webp");
    background-size: cover;
}

.home-banner {
    margin-top: 3rem;
    color: var(--white-color) !important;
    padding: 2rem;
    background-color: #0000007e;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.071);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.home-banner h2 {
    font-size: 3.5rem;
    color: var(--second-main-color);
    line-height: 3.5rem;
    margin-top: -10px;
}

.home-banner p:first-child {
    width: fit-content;
    padding: 5px 10px;
    border-radius: .25rem;
    font-size: 1.5rem !important;
}

.home-banner p {
    font-size: 1.1rem;
}

.home-banner ul li {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--white-color);
    padding-right: 2.9rem;
    font-size: 1.1rem;
}

.home-banner ul li:last-child {
    border: none;
    padding: 0;
}

.home-banner ul li span:first-child {
    font-size: 2rem;
}

@media (max-width:992px) {
    .Main-slider {
        height: auto;
    }

    .home-banner {
        margin: 1rem 0;
    }

    .home-banner h2 {
        font-size: 1.8rem;
        line-height: 2.3rem;
    }

    .home-banner p {
        font-size: 1rem !important;
    }

    .home-banner p:first-child {
        font-size: 1.1rem !important;
    }

    .home-banner ul li {
        border: none;
        margin-bottom: 1rem;
    }
}


.input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
.news-input input:-internal-autofill-selected,
.news-input input{
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #FFFFFF !important;
    color: #FFFFFF !important;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

@-webkit-keyframes autofill {

    0%,
    100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s;
    /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}
