
.form-container {
    background: #f4f4f4;
    border-radius: 1rem;
    padding: 1rem;
}
.form-container .form-field{
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.form-container form{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.form-container form label{
    margin-left: .5rem;
    margin-bottom: .5rem;
}
.form-container input, .form-container select,
.form-container textarea{
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #e5e5e5;
}

.default-btn{
    /* background-color: var(--second-main-color); */
    background-color: var(--main-color);
    border: none;
    font-weight: bold;
    border-radius: .25rem;
    padding: .3rem 1rem;
}
