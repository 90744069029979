.project-card {
    border: 1px solid #e5e5e5;
    background-color: var(--white-color);
    padding: 1rem;
    border-radius: 1rem;
    text-align: center;
  }
  .project-card img {
    border-radius: 1rem;
  }
  
  
  .project-content h5{
      margin-bottom: 1rem;
  }
  .project-content h5 a:hover{
      color: var(--black-color);
  }