.event-right .event-card {
  height: 250px;
}
.event-right .event-card {
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 0.7rem;
  height: 100%;
}
.event-right .event-card img {
  height: 100%;
  width: 35%;
  border-radius: 1rem 0 0 1rem;
}
.event-right-content {
  padding: 1rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
.event-right-content h4{
  transition: 0.4s;
  margin-top: 10px;
  cursor: pointer;
}
.event-right-content h4 a:hover {
  color: var(--second-main-color);
}
.event-right-content span {
  /* color: var(--second-main-color); */
  color: var(--main-color);
}
.event-inner span{
  color: var(--second-main-color);
}