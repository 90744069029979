@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
:root {
  --color-dark: #333333;
  --color-linkedin: #0072b1;
  --color-facebook: #1877F2;
  --color-instagram: #E1306C;
  --color-whatsapp: #25D366;
  --color-twitter: #1DA1F2;
  --color-youtube: #FF0000;
  --color-light: #eeeeee;
  --main-color: #0c0fb3;
  --second-main-color: #FF7903;
  --white-color: #fff;
  --black-color: #000000;
  --font-theme: 'Poppins', sans-serif;
}

html,
body {
  font-family: var(--font-theme);
  color: var(--color-dark);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

.text-justify {
  text-align: justify !important;
}

.switch-box {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  border-radius: 50px;
}
.main-text {
  font-weight: 300;
  font-size: 1.5rem;
}

.main-title{
  font-weight: 500;
}
.main-heading {
  position: relative;
  margin-bottom: 2rem;
}
.main-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 2px;
  background-color: var(--second-main-color);
}
.main-heading-left::before{
    left: 0;
    transform: translateX(0);
    bottom: -10px;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color: var(--black-color);
}


.image-card-main>.div-field {
  width: 200px;
  height: 200px;
  border: 1px solid #c1bebe;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.title {
  font-size: 2rem;
  text-align: center !important;
  margin-top: 10%;
  color: #4aa1f3;
  font-weight: bold;
}

.content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}