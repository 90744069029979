
.form-radio input {
  margin-left: 1rem;
  margin-right: 5px;
}
.custom-file-upload {
  border: 1px solid #c1bebe;
  border-radius: 1rem;
  width: 100%;
  height: 200px;
  padding: 5px;
}
.obimages,
.obimages:before {
  width: 100%;
  height: 100%;
}
.custom-file-upload img {
  object-fit: cover;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--white-color);
  background: var(--main-color);
}
.form-field span {
  margin-left: 0.5rem;
}
.members-card{
  border: 1px solid #c1bebe;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.members-card img{
  width: 100%;
  height: 100%;
}
.members-card .form-radio{
  flex-direction: row;
}

.members-card-main > .div-field{
  width: 200px;
  height: 200px;
  border: 1px solid #c1bebe;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}