.partner-card{
    padding: 1rem;
    border-radius: 1rem;
    background-color: var(--white-color);
}

.partner-img {
    width: 250px;
    height: 80px;
    margin: 0 auto;
    text-align: center;
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e5e5;
    display: flex;
}